<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    <div class="container">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-5">
                <div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="">
                        <div class="">
                          <div class="row">
                            <div class="col-xl-12">
                              <div class="product-detail">
                                <div class="row">
                                  <div class="col-md-12 col-2">
                                    <div
                                      class="tab-content"
                                      id="v-pills-tabContent"
                                    >
                                      <div
                                        v-if="product1"
                                        class="tab-pane fade active show"
                                        id="product-1"
                                        role="tabpanel"
                                      >
                                        <div
                                          class="product-img"
                                          style="
                                            padding: 5px;
                                            width: 450px;
                                            height: 350px;
                                          "
                                        >
                                          <img
                                            style="
                                              max-width: 100%;
                                              max-height: 100%;
                                            "
                                            src="http://v4-upload.goalsites.com/141/image_1527561188_SZD700.jpg"
                                            alt="img-1"
                                            class="
                                              img-fluid
                                              mx-auto
                                              d-block
                                              tab-img
                                              rounded
                                            "
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-3">
                                    <div
                                      style=""
                                      class="nav flex-column nav-pills"
                                      id="v-pills-tab"
                                      role="tablist"
                                      aria-orientation="vertical"
                                    >
                                      <a
                                        style="width: 120px; height: 60px"
                                        class="nav-link"
                                        @click="wehed()"
                                        id="product-1-tab"
                                        data-bs-toggle="pill"
                                        role="tab"
                                        aria-selected="false"
                                      >
                                        <img
                                          style="
                                            border-style: solid;
                                            border-width: 1px;
                                            max-width: 100%;
                                            max-height: 100%;
                                          "
                                          src="http://v4-upload.goalsites.com/141/image_1527561188_SZD700.jpg"
                                          alt="img-1"
                                          class="
                                            img-fluid
                                            mx-auto
                                            d-block
                                            rounded
                                          "
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- end product img -->
                            </div>
                          </div>
                          <!-- end row -->
                        </div>
                      </div>
                      <!-- end card -->
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div class="col-xl-7">
                <div class="row">
                  <div class="col-1"></div>
                  <div class="mt-1 col-11">
                    <div class="row">
                      <h1 class="font-size-48 mb-3">
                        <strong> 
Fully automatic glue dispenser machine SZD-700 </strong>
                      </h1>
                    </div>
                    <br />
                    <div class="row">
                      <h5 style="font-weight: 700; font-size: 18px">
                        Brand :
                        <span
                          style="
                            color: black;
                            font-weight: 400;
                            font-size: 16px;
                          "
                          >D&H</span
                        >
                      </h5>
                    </div>
                    <br />
                    <div class="row">
                      <h5 style="font-weight: 700; font-size: 18px">
                        Model :
                        <span
                          style="
                            color: black;
                            font-weight: 400;
                            font-size: 16px;
                          "
                          >SZD-700</span
                        >
                      </h5>
                    </div>
                    <br />
                    <div class="row">
                      <h5 style="font-weight: 700; font-size: 18px">
                        Applications :
                        <span
                          style="
                            color: black;
                            font-weight: 400;
                            font-size: 16px;
                          "
                          >The electronics, LED, crafts and where glue dispense demanded products.</span
                        >
                      </h5>
                    </div>
                    <br />
                    <div class="row">
                      <h5 style="font-weight: 700; font-size: 18px">
                        Suitable Material :
                        <span
                          style="
                            color: black;
                            font-weight: 400;
                            font-size: 16px;
                          "
                          >One-component glue or already mixed glue ,Solder Paste, Epoxy, Silicone, PU, Black Glue, White Glue, etc</span
                        >
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end row -->

            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
            <div class="row">
              <div class="col-xl-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-xl-6">
                        <!-- 16:9 aspect ratio -->
                        <div class="ratio ratio-21x9">
                          <iframe
                            style="width: 450px; height: 250px"
                            title="YouToube Video"
                            src="https://www.youtube.com/embed/itwgU-lzs7A" 
                            allowfullscreen=""
                          ></iframe>
                        </div>
                      </div>

                      <div class="col-xl-6">
                        <h1 style="color: black; font-weight: 700">Features</h1>
                        <br />

                        <div class="row" style="font-size: 14px">
                          1、 High precision controller ensures good glue
                          precision
                        </div>
                        <br />
                        <div class="row" style="font-size: 14px">
                          2、Accurate position and movement; easy adjustment
                        </div>
                        <br />
                        <div class="row" style="font-size: 14px">
                          3、Good universality
                        </div>
                        <br />
                        <div class="row" style="font-size: 14px">
                          4、Easy program & operation
                        </div>
                        <br />
                        <div class="row" style="font-size: 14px">
                          4、 Other functions can be available to meet up
                          customers' requirement.
                        </div>
                        <br />
                      </div>
                      <!-- end col -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->
            </div>
            <br /><br />

            <br />
            <b-tabs pills card style="font-size: 18px">
              <b-tab active title="Technical Data">
                <div class="row">
                  <div class="col-1"></div>
                  <div class="col-10">
                    <ul class="container">
                      <li>
                        <strong style="color: black"> Working Area:</strong>
                        700*700*80mm, or customized;
                      </li>
                      <li>
                        <strong style="color: black"> Running Path:</strong>
                        dot,line, arc ,circle....
                      </li>
                      <li>
                        <strong style="color: black"> Moving Speed:</strong>
                        0-300mm/s adjustable
                      </li>
                      <li>
                        <strong style="color: black"> Repeatability:</strong>
                        ±1%
                      </li>
                      <li>
                        <strong style="color: black">Mixing Accuracy: </strong>
                        ±1%
                      </li>
                      <li>
                        <strong style="color: black">Tank Capacity: </strong>
                        55ml, 200ml,300ml 2.6Lor customized
                      </li>

                      <li>
                        <strong style="color: black">Gluing Speed: </strong>
                        5-66g/5s adjustable
                      </li>
                      <li>
                        <strong style="color: black">Air Pressure: </strong>
                        0.5-0.7Mpa
                      </li>
                      <li>
                        <strong style="color: black">Power Input: </strong>
                        AC220V 50-60Hz or customized
                      </li>
                      <li>
                        <strong style="color: black">Dimension: </strong>
                         1100*1100*1328mm
                      </li>
                      <li>
                        <strong style="color: black">Weight: </strong> 350KG
                        roughly
                      </li>
                    </ul>
                    <img
                      src="https://v4-upload.goalsites.com/141/editor_1536303186_szd700.jpg"
                      alt="img-1"
                      class="img-fluid mx-auto d-block rounded"
                    />
                  </div>
                </div>
              </b-tab>
              <b-tab title="The Working Principle">
                <div class="row">
                  <div class="col-1"></div>
                  <div class="col-10">
                    1. Put the adhesive into the syringe, cover it with the lid,
                    install it onto the fixture <br />

                    2. Edit the program according to the demanded running path
                    and save the program when it finished. <br />

                    3.Adjust the controller according to demanded glue amount in
                    the unit product, and save the data when setting is
                    finished. <br />

                    4. Make trial production to check if everything goes well;
                   
                  </div>
                </div>
              </b-tab>
              <b-tab title="Other information">
                <div class="row">
                  <img
                    src="https://v4-upload.goalsites.com/141/editor_1536303186_700.jpg"
                    alt="img-1"
                    class="img-fluid mx-auto d-block rounded"
                  />
                </div>
               
              </b-tab>
            </b-tabs>

            <br /><br />
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  data() {
    return {
      product1: true,
    };
  },
  components: {
    Header,

    Footer,
  },
  methods: {
    wehed() {
      (this.product1 = true),
        (this.product2 = false),
        (this.product3 = false),
        (this.product4 = false);
    },

    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>